/**
 * Enum for batch processing types
 * Used by both client and server code to ensure consistency
 */
export enum EnumBatchType {
  /**
   * For processing reserved lists (standard lists)
   */
  RESERVED_LISTS = 0,
  
  /**
   * For processing user-created lists
   */
  USER_LISTS = 1,
  
  /**
   * For user preparation after list processing
   */
  USER_PREP = 2,
  
  /**
   * For fulfillment processing
   */
  FULFILLMENT = 3
}

/**
 * String constants for backward compatibility
 */
export const BATCH_TYPE_RESERVED_LISTS = 'RESERVED_LISTS';
export const BATCH_TYPE_USER_LISTS = 'USER_LISTS';
export const BATCH_TYPE_USER_PREP = 'USER_PREP';
