import NewsInfo from "./newsInfo";

export default class ListNews {
  listId: string;
  listName: string;
  newsItems: NewsInfo[];
  symbols: string[];

  constructor(listId: string, listName: string, newsItems: NewsInfo[] = [], symbols: string[] = []) {
    this.listId = listId;
    this.listName = listName;
    this.newsItems = newsItems;
    this.symbols = symbols;
  }

  /**
   * Alias for newsItems to maintain backward compatibility
   */
  get newsInfos(): NewsInfo[] {
    return this.newsItems;
  }

  static fromJSON(json: any): ListNews {
    return new ListNews(
      json.listId || '',
      json.listName || '',
      json.newsItems?.map((item: any) => NewsInfo.fromJSON(item)) || [],
      json.symbols || []
    );
  }
}
