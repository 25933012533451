"use client"
import {
  addDoc,
  collection,
  doc,
  getDoc,
  DocumentReference,
  getDocs,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  Unsubscribe,
  where,
  documentId,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  deleteDoc,
  CollectionReference,
  writeBatch
} from "@firebase/firestore"

import { httpsCallable } from "firebase/functions"

import { useEffect, useState } from "react"
import ListNews from "@entities/listNews"
import NewsInfo from "@entities/newsInfo"
import ItemInfo from "@entities/itemInfo"
import ProfitDotComTickerQuote from '@entities/profitDotComTickerQuote'
import ListInfo from "@/functions/src/entities/listInfo"
import { app, db, functions } from "@/hooks/firebase.client.config"
import UserInfo2 from "@/functions/src/entities/userInfo2"
import { EnumCollections } from "@/functions/src/entities/enumCollections"
import DateTimeHelper from "@/functions/src/date-time-helper"
import {auth} from "@/hooks/firebase.client.config"
import { EnumBatchType, BATCH_TYPE_RESERVED_LISTS, BATCH_TYPE_USER_LISTS, BATCH_TYPE_USER_PREP } from "@/functions/src/entities/enumBatchType"

// For backward compatibility and to avoid breaking changes
export const BATCH_PROCESS_LIST_RESERVED = EnumBatchType.RESERVED_LISTS;
export const BATCH_PROCESS_LIST_USERS = EnumBatchType.USER_LISTS;
export const BATCH_USER_PREP = EnumBatchType.USER_PREP;
export const BATCH_FULFILLMENT = EnumBatchType.FULFILLMENT;

export function useFirebaseService() {
  const COLL_NEWS = "news"
  const COLL_ITEMS = "items"
  const COLL_LISTS = "lists"
  const COLL_VISITORS = "visitors"
  const COLL_USERS = "users"
  const COLL_BATCH_RUNS = "batchruns"
  const COLL_LIST_RUNS = "listruns"
  const BATCH_SIZE = 10

  // Helper function to enrich user info with Stripe and subscription data
  const enrichUserInfo = async (basicUser: UserInfo2): Promise<UserInfo2> => {
    try {
      console.log('Enriching user info for:', basicUser.email);
      
      // Step 1: Enrich with Stripe customer data
      console.log('Step 1: Getting Enriched customer data');
      const stripeEnrichedUser = await confirmCustomer(basicUser);
      console.log('Step 1.1: Enriched customer data:', stripeEnrichedUser);
      if (!stripeEnrichedUser) {
        console.log('Failed to get Stripe data, falling back to basic user');
        return basicUser;
      }

      // Step 2: Enrich with subscription status
      // console.log('Step 2: Getting subscription status');
      // const hasActiveSub = await hasActiveSubscription(stripeEnrichedUser.uid);
      // stripeEnrichedUser.hasActiveSubscription = hasActiveSub;
      // console.log('Enrichment complete:', {
      //   email: stripeEnrichedUser.email,
      //   stripeId: stripeEnrichedUser.stripeCustomerId,
      //   hasSubscription: stripeEnrichedUser.hasActiveSubscription
      // });

      return stripeEnrichedUser;
    } catch (error) {
      console.error('Error during user info enrichment:', error);
      return basicUser; // Fallback to basic user info if enrichment fails
    }
  };

  const [isInitialized, setIsInitialized] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [isSubscribed, setIsSubscribed] = useState(false)  // Always false for now until auth is implemented

  useEffect(() => {
    try {
      // Check if Firebase app is available
      if (!app?.options) {
        setError(new Error('Firebase app is not properly configured'))
        return
      }

      // Check if Firestore is available
      if (!db) {
        setError(new Error('Firestore is not properly configured'))
        return
      }

      // If we get here, Firebase is properly initialized
      setIsInitialized(true)
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Firebase initialization failed'))
    }
  }, [])

  useEffect(() => {
    setIsSubscribed(false);
  }, []);

  function docSnapshotToDataWithId(docSnap: QueryDocumentSnapshot) {
    return {
      id: docSnap.id,
      ...docSnap.data()
    }
  }

  function querySnapshotToDataWithId(qSnapshot: any) {
    return (qSnapshot.docs || []).map((doc: any) => docSnapshotToDataWithId(doc));
  }

  function getSnapshot(collectionName: string, callback: any): Unsubscribe {
    const collRef:CollectionReference = collection(db, collectionName);
    return onSnapshot(collRef, callback);
  }

  async function getUserByEmail(email: string): Promise<UserInfo2 | null> {
    const q = query(collection(db, COLL_USERS), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return null;
    const retVal = querySnapshot.docs[0].data() as UserInfo2
    // console.log("==========================================");
    // console.log(`Current User Data at getUserByEmail: ${JSON.stringify(retVal)}`);
    // console.log("==========================================");
    return retVal;
  }
  
  async function getCustomerByEmail(email: string): Promise<{exists: boolean, hasStripeCustomer: boolean}> {
    try {
      const getCustomerByEmailFn = httpsCallable<{email: string}, {exists: boolean, hasStripeCustomer: boolean}>(functions, 'getCustomerByEmail');
      const result = await getCustomerByEmailFn({ email });
      return result.data;
    } catch (error) {
      console.error('Error checking customer by email:', error);
      // Default to not existing if there's an error
      return { exists: false, hasStripeCustomer: false };
    }
  }

  const getItemDoc = (id: string) => {
    if (!id) return null;
    return doc(db, COLL_ITEMS, id);
  }

  const getNewsDoc = async (symbol: string) => {
    const docs = await getDocumentsByField(COLL_NEWS, "symbol", symbol);
    return docs.length > 0 ? docs[0] : null;
  }

  const getDocumentsByField = async (collectionName: string, field: string, value: any): Promise<any[]> => {
    let qry = query(collection(db, collectionName), where(field, "==", value));
    const docs = (await getDocs(qry)).docs;
    return docs.map(doc => doc.data());
  };

  const getNewsItemSnapshot = (
    symbol: string,
    onData: (data: any) => void,
    onError: (error: any) => void
  ): Unsubscribe => {
    if (!symbol) {
      console.error('Symbol is required for getNewsItemSnapshot');
      return () => { };
    }

    // console.log('getNewsItemSnapshot called for symbol:', symbol);
    const newsRef = doc(db, COLL_NEWS, symbol.toUpperCase());
    return onSnapshot(
      newsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          // console.log(`News data for ${symbol}:`, data);
          if (data) {
            onData({
              id: snapshot.id,
              symbol: symbol.toUpperCase(),
              ...data
            });
          } else {
            onData(createDefaultNewsItem(symbol, symbol));
          }
        } else {
          onData(createDefaultNewsItem(symbol, symbol));
        }
      },
      onError
    );
  }

  const getItemsSnapshot = (items: string[], handleData: (doc: any) => void, handleError: (error: any) => void): Unsubscribe => {
    // console.log('getItemsSnapshot called for items:', items);
    try {
      if (!db) throw new Error('Firebase is not configured');
      if (!items || items.length === 0) {
        handleData([]);
        return () => { }; // Return empty unsubscribe function if no items
      }

      // Create a query that matches any document whose ID is in the items array
      const q = query(
        collection(db, COLL_ITEMS),
        where(documentId(), 'in', items)
      );

      // Set up the snapshot listener
      return onSnapshot(
        q,
        (snapshot) => {
          const documents = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          handleData(documents);
        },
        handleError
      );
    } catch (error) {
      console.error('Error in getItemsSnapshot:', error);
      handleError(error);
      return () => { }; // Return empty unsubscribe function on error
    }
  }

  const addNews = async (): Promise<DocumentReference | null> => {
    try {
      const docRef = await addDoc(collection(db, COLL_NEWS), {
        name: "Tokyo",
        country: "Japan"
      });

      console.log("Document written with ID: ", docRef.id);
      return docRef;

    } catch (error) {
      console.error("Error adding document: ", error);
      return null;
    }
  }

  const updateVisitorCount = async (ip: string): Promise<void> => {
    const today = new Date().toISOString().split('T')[0];
    const visitorRef = doc(db, COLL_VISITORS, today);

    try {
      const docSnap = await getDoc(visitorRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (!data.ips.includes(ip)) {
          await updateDoc(visitorRef, {
            count: data.count + 1,
            ips: arrayUnion(ip)
          });
        }
      } else {
        await setDoc(visitorRef, {
          date: today,
          count: 1,
          ips: [ip]
        });
      }
    } catch (error) {
      console.error("Error updating visitor count:", error);
    }
  };

  const getVisitorCountSnapshot = (
    onData: (count: number) => void,
    onError: (error: Error) => void
  ): Unsubscribe => {
    const today = new Date().toISOString().split('T')[0];
    const visitorRef = doc(db, COLL_VISITORS, today);

    return onSnapshot(
      visitorRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          onData(data.count || 0);
        } else {
          onData(0);
        }
      },
      onError
    );
  };

  async function getListData(listId: string): Promise<any> {
    const listRef = doc(db, COLL_LISTS, listId);
    const listDoc = await getDoc(listRef);
    return listDoc.data() || {};
  }

  async function getListNewsInfo(listId: string): Promise<ListNews> {
    const defaultList = [createDefaultNewsItem()];

    try {
      // Step 1: Get list data
      const listData = await getListData(listId);
      const itemIds = listData.items || [];
      const listName = listData.name || "";

      // If no items in list, return early with default
      if (!itemIds.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 2: Get item information for all items
      const itemInfos: ItemInfo[] = [];
      try {
        const itemsSnapshot = await getDocs(
          query(collection(db, COLL_ITEMS),
            where(documentId(), 'in', itemIds))
        );

        itemsSnapshot.forEach(doc => {
          const data = doc.data();
          if (data && data.symbol) {  // Only add items with valid symbols
            itemInfos.push(data as ItemInfo);
          }
        });
      } catch (error) {
        console.error('Error fetching item information:', error);
        return new ListNews(listId, listName, defaultList);
      }

      // If no valid items found, return early with default
      if (!itemInfos.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 3: Extract and filter valid symbols
      const symbols = itemInfos
        .map(item => item.symbol)
        .filter((symbol): symbol is string => !!symbol);

      if (!symbols.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 4: Get news items for all symbols
      let newsItems: NewsInfo[] = [];
      try {
        const newsSnapshot = await getDocs(
          query(collection(db, COLL_NEWS),
            where(documentId(), 'in', symbols))
        );

        newsItems = newsSnapshot.docs
          .map(doc => NewsInfo.create(doc.id, doc.data()))
          .filter(item => item.long || item.short); // Only include items with content
      } catch (error) {
        console.error('Error fetching news items:', error);
        return new ListNews(listId, listName, defaultList);
      }

      // Return with default list if no valid news items found
      return new ListNews(
        listId,
        listName,
        newsItems.length ? newsItems : defaultList
      );

    } catch (error) {
      console.error('Error in getListNewsInfo:', error);
      return new ListNews(listId, "", []);
    }
  }

  const getMultipleListsNewsInfo = async (listIds: string[]): Promise<ListNews[]> => {
    const results: ListNews[] = [];
    for (const listId of listIds) {
      const listNews = await getListNewsInfo(listId);
      results.push(listNews);
    }
    return results;
  }

  const getListsForUser = async (userId: string): Promise<string[]> => {
    try {
      console.log('getListsForUser called for userId:', userId);
      if (!db) throw new Error('Firebase is not configured')

      const userRef = doc(db, COLL_USERS, userId)
      const userDoc = await getDoc(userRef)

      if (!userDoc.exists()) {
        console.log('User document not found, creating new one');
        // Create user document if it doesn't exist
        await setDoc(userRef, { lists: [] })
        return []
      }

      const userData = userDoc.data()
      console.log('User data retrieved:', userData);
      return userData?.lists || []
    } catch (error) {
      console.error('Error in getListsForUser:', error)
      throw error
    }
  }

  const addUserList = async (userId: string, listName: string): Promise<string> => {
    if (!listName.trim()) {
      throw new Error("List name cannot be empty")
    }

    try {
      // Create the new list
      const newList = {
        name: listName.trim(),
        items: [],
        userId,
        createdOn: serverTimestamp()
      }
      const listRef = await addDoc(collection(db, COLL_LISTS), newList)

      // Add the list reference to the user's lists
      const userRef = doc(db, 'users', userId)
      await updateDoc(userRef, {
        lists: arrayUnion(listRef.id)
      })

      return listRef.id
    } catch (error) {
      console.error('Error adding list:', error)
      throw error
    }
  }

  const deleteList = async (userId: string, listId: string): Promise<void> => {
    if (!listId) {
      throw new Error("List ID is required")
    }

    try {
      // First, remove the list from user's lists array
      const userRef = doc(db, COLL_USERS, userId)
      await updateDoc(userRef, {
        lists: arrayRemove(listId)
      })

      // Then delete the list document
      const listRef = doc(db, COLL_LISTS, listId)
      await deleteDoc(listRef)
    } catch (error) {
      console.error('Error deleting list:', error)
      throw error
    }
  }

  function createDefaultNewsItem(itemId: string = 'default', symbol: string = ''): NewsInfo {
    const now = new Date();
    const newsItem = new NewsInfo(itemId, itemId);
    newsItem.symbol = symbol;
    newsItem.long = `No News for: ${symbol || 'Unknown Ticker'}`;
    newsItem.short = `No News for: ${symbol || 'Unknown Ticker'}`;
    newsItem.articles = [];
    newsItem.isArticlesUpdated = false;
    newsItem.createdOn = now;
    newsItem.modifiedOn = now;
    newsItem.refreshedOnL = now.getTime();
    return newsItem;
  }

  const cleanup = async () => {
    // Add any cleanup logic here if needed
    // This is a no-op for now since we're using one-time fetches
    return Promise.resolve();
  }

  const addItemToWatchlist = async (listId: string, quoteData: ProfitDotComTickerQuote): Promise<string> => {
    try {
      if (!db) throw new Error('Firebase is not configured')
      if (!quoteData?.symbol) throw new Error('Symbol is required')
      if (!listId) throw new Error('List ID is required')

      const itemsRef = collection(db, COLL_ITEMS)
      const itemQuery = query(itemsRef, where('symbol', '==', quoteData.symbol.toUpperCase()))
      const existingItems = await getDocs(itemQuery)

      let itemId: string
      const itemToSave = ProfitDotComTickerQuote.toItemInfo(quoteData)
      const now = new Date()

      // console.log("==========================================");
      // console.log(`Quote Data: ${JSON.stringify(quoteData)}`);
      // console.log("==========================================");

      if (existingItems.empty) {
        const newItemRef = await addDoc(itemsRef, {
          ...itemToSave,
          createdOn: now
        })
        itemId = newItemRef.id
      } else {
        itemId = existingItems.docs[0].id
        await updateDoc(doc(db, COLL_ITEMS, itemId), {
          ...itemToSave,
          modifiedOn: now
        })
      }

      // Now update the list with the new item
      const listRef = doc(db, COLL_LISTS, listId)
      const listDoc = await getDoc(listRef)

      // console.log("==========================================");
      // console.log(`List ${listId} exists:`, listDoc.exists());
      // console.log(`List data:`, listDoc.data());
      // console.log("==========================================");

      if (!listDoc.exists()) {
        // Create the list if it doesn't exist
        await setDoc(listRef, {
          name: listId,
          items: [],
          createdOn: new Date(),
          modifiedOn: new Date()
        });
      }

      const currentItems = listDoc.data()?.items || []

      if (currentItems.includes(itemId)) {
        throw new Error(`${quoteData.symbol} is already in this watchlist`)
      }

      await updateDoc(listRef, {
        items: arrayUnion(itemId),
        modifiedOn: now,
      })
      
      return itemId
    } catch (error) {
      console.error('Error adding item to watchlist:', error)
      throw error
    }
  }

  const getListInfo = async (listId: string): Promise<ListInfo | null> => {
    try {
      const listRef = doc(db, COLL_LISTS, listId)
      const listDoc = await getDoc(listRef)
      return listDoc.exists() 
        ? { id: listDoc.id, ...listDoc.data() } as ListInfo 
        : null
    } catch (error) {
      console.error('Error fetching list item:', error)
      throw error
    }
  }

  const getVisitors = async () => {
    try {
      const visitorsRef = doc(db, COLL_VISITORS, "2024-11-25");
      const visitorsDoc = await getDoc(visitorsRef)
      return visitorsDoc.exists() ? visitorsDoc.data() : {}
    } catch (error) {
      console.error('Error fetching visitors:', error)
      throw error
    }
  }

  const subscribeToNewsletter = async (email: string) => {
    try {
      const subscribersRef = collection(db, 'subscribers');
      await addDoc(subscribersRef, {
        email,
        subscribedAt: serverTimestamp(),
        status: 'active'
      });
      return true;
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      throw error;
    }
  }

  async function saveUserRemote(userInfo: UserInfo2): Promise<any> {
    try {
      if (!userInfo?.uid) {
        throw new Error('User ID is required');
      }

      const docRef = doc(db, `${EnumCollections.COL_USERS}/${userInfo.uid}`);
      const now = DateTimeHelper.getNow();

      // First check if the document exists
      const docSnap = await getDoc(docRef).catch(error => {
        console.error('Error fetching user document:', error);
        throw new Error('Failed to fetch user document');
      });

      let updatedUserInfo: UserInfo2;

      if (docSnap.exists()) {
        // Update existing document
        updatedUserInfo = {
          ...userInfo,
          modifiedOn: now
        };
      } else {
        // Create new document
        updatedUserInfo = {
          ...userInfo,
          createdOn: now,
          modifiedOn: now
        };
      }

      await setDoc(docRef, updatedUserInfo, { merge: true }).catch(error => {
        console.error('Error saving user document:', error);
        throw new Error('Failed to save user document');
      });

      return { ...updatedUserInfo, id: userInfo.uid };
    } catch (error) {
      console.error('Error in saveUserRemote:', error);
      throw error instanceof Error ? error : new Error('An unexpected error occurred while saving user data');
    }
  }

  const getHttpsCallable = (name: string) => {
    return httpsCallable(functions, name);
  };


  /**
   * Generates a text-based tree representation of the Firestore database structure
   * @returns Promise with the text representation
   */
  const generateFirestoreGraphText = async (): Promise<string> => {
    try {
      // Define the expected response type
      const callable = httpsCallable<any, { success: boolean, data: string }>(functions, 'generateFirestoreGraphText');
      
      // Add timeout to prevent hanging indefinitely
      const timeoutPromise = new Promise<never>((_, reject) => {
        setTimeout(() => reject(new Error('Cloud Function call timed out after 30 seconds')), 30000);
      });
      
      // Race between the actual call and the timeout
      const result = await Promise.race([
        callable({}),
        timeoutPromise
      ]) as any;
      
      if (!result || !result.data) {
        throw new Error('No data received from Cloud Function');
      }
      
      // Check if result.data is already a string (direct response)
      if (typeof result.data === 'string') {
        return result.data;
      }
      
      // // If result.data is an object with a success property, it's the response from the Cloud Function
      // if (typeof result.data === 'object' && result.data.success) {
      //   // If result.data has a data property that's a string, use that
      //   if (typeof result.data.data === 'string') {
      //     return result.data.data;
      //   }
        
      //   // Otherwise, assume the graph text is in another property or needs to be extracted
      //   return result.data.toString();
      // }
      
      // // If result.data is an object, convert it to a string
      // if (typeof result.data === 'object') {
      //   // Check if it has a toString method that produces the graph text
      //   if (typeof result.data.toString === 'function' && 
      //       result.data.toString() !== '[object Object]' && 
      //       result.data.toString().includes('Firestore Database')) {
      //     return result.data.toString();
      // //   }
        
      //   // Otherwise, stringify it
      //   return JSON.stringify(result.data, null, 2);
      // }
      
      // If all else fails, convert result.data to a string
      return String(result.data);
    } catch (error: any) {
      // If the error message contains the graph text, return it
      if (error.message && error.message.includes('Firestore Database')) {
        return error.message;
      }
      
      throw error;
    }
  };

  /**
 * Used to get the client secret for the payment session
 * @param priceId
 */
  const createCheckoutSession = async (priceId: string, stripeCustomerId: string, returnUrl: string): Promise<string | null> => {
    const callable = httpsCallable<
      { priceId: string, stripeCustomerId: string, returnUrl: string }, 
      { clientSecret: string | null }
    >(functions, 'createCheckoutSession');
    
    // console.log("Going to call checkoutSession with:", { priceId, stripeCustomerId, returnUrl });
    const result = await callable({ priceId, stripeCustomerId, returnUrl });
    return result.data.clientSecret;
  };

  /**
   * Confirms a user in both Firebase and Stripe
   * @param userInfo The user info to confirm
   * @returns Updated UserInfo2 with Stripe customer ID or null if failed
   */
  const confirmCustomer = async (userInfo: UserInfo2): Promise<UserInfo2 | null> => {
    try {
      const callable = httpsCallable<
        { data: UserInfo2 }, 
        { result: boolean; userInfo: UserInfo2 | null }
      >(functions, 'confirmCustomer');

      // console.log('==========================================');
      // console.log('Calling confirmCustomer with:', userInfo);
      // console.log('==========================================');

      const result = await callable(JSON.parse(JSON.stringify(userInfo)));
      // console.log('Confirm customer result:', result.data);
      
      if (result.data.result && result.data.userInfo) {
        return result.data.userInfo;
      }
      return null;
    } catch (error) {
      console.error('Error confirming customer:', error);
      return null;
    }
  };

  function getPaymentInfosSnapshot(uid: string, callback: any): Unsubscribe {
    const collPath = `${EnumCollections.COL_USERS}/${uid}/${EnumCollections.COL_PAYMENTS}`;
    return getSnapshot(collPath, callback);
  }

  function getSubscriptionsSnapshot(uid: string, callback: any): Unsubscribe {
    const collPath = `${EnumCollections.COL_USERS}/${uid}/${EnumCollections.COL_SUBSCRIPTIONS}`;
    console.log("path", collPath);
    return getSnapshot(collPath, callback);
  }

  // function getSubscriptionsForUser(uid:string): Promise<SubscriptionInfo[]> {
  //   return new Promise((resolve) => {
  //     getSubscriptionsSnapshot(uid, (subscriptions: any) => {
  //       console.log("Subscriptions:", subscriptions);
  //       const allSubs = querySnapshotToDataWithId(subscriptions);
  //       console.log("All subscriptions:", allSubs);
  //       resolve(allSubs);
  //     });
  //   });
  // }

  // async function hasActiveSubscription(uid: string): Promise<boolean> {
  //   const subscriptions = await getSubscriptionsForUser(uid);
  //   return subscriptions.some(sub => 
  //     sub.status === 'active' && 
  //     !sub.cancelAtPeriodEnd && 
  //     sub.currentPeriodEnd.toDate() > new Date()
  //   );
  // }

  const getListInfosForUser = async (userId: string): Promise<ListInfo[]> => {
    try {
      console.log('getListInfosForUser called for userId:', userId);
      
      // First get the list IDs for the user
      const listIds = await getListsForUser(userId);
      
      if (!listIds.length) {
        console.log('User has no lists');
        return [];
      }
      
      // Then fetch the details for each list
      const listsData = await Promise.all(
        listIds.map(async (id) => {
          try {
            const list = await getListInfo(id);
            return list;
          } catch (err) {
            console.error(`Error fetching list ${id}:`, err);
            return null;
          }
        })
      );
      
      // Filter out any null values and return the array of ListInfo objects
      const listInfos = listsData.filter((list): list is ListInfo => !!list && !!list.name);
      console.log(`Retrieved ${listInfos.length} lists for user ${userId}`);
      
      return listInfos;
    } catch (error) {
      console.error('Error in getListInfosForUser:', error);
      return [];
    }
  }

  /**
   * Get all users from the database
   * @returns Promise with array of UserInfo2 objects
   */
  async function getAllUsers(): Promise<UserInfo2[]> {
    const usersRef = collection(db, COLL_USERS);
    const querySnapshot = await getDocs(usersRef);
    const users: UserInfo2[] = [];
    
    querySnapshot.forEach((doc) => {
      const userData = doc.data() as UserInfo2;
      users.push(userData);
    });
    
    return users;
  }

  /**
   * Subscribe to the users collection
   * @param callback Function to call when users collection changes
   * @returns Unsubscribe function
   */
  function subscribeToUsers(callback: (users: UserInfo2[]) => void): Unsubscribe {
    const usersRef = collection(db, COLL_USERS);
    
    return onSnapshot(usersRef, (querySnapshot) => {
      const users: UserInfo2[] = [];
      
      querySnapshot.forEach((doc) => {
        const userData = doc.data() as UserInfo2;
        // Make sure to include the document ID as the uid
        userData.uid = doc.id;
        users.push(userData);
      });
      
      callback(users);
    });
  }

  /**
   * Delete a user by their user ID
   * @param userId The ID of the user to delete
   * @returns Promise that resolves when the user is deleted
   */
  async function deleteUser(userId: string): Promise<void> {
    try {
      // Ensure the user is authenticated
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('You must be logged in to delete a user');
      }

      // Get the current user's token to ensure it's fresh
      await currentUser.getIdToken(true);
      console.log(`Attempting to delete user ${userId} as ${currentUser.uid}`);
      
      // Call the cleanupUserData Cloud Function
      const cleanupUserDataFn = httpsCallable(functions, 'cleanupUserData');
      const result = await cleanupUserDataFn({ uid: userId });
      console.log(`User ${userId} deletion result:`, result.data);
    } catch (error) {
      console.error(`Error deleting user ${userId}:`, error);
      throw error;
    }
  }

  /**
   * Trigger manual newsletter send for a specific user
   * @param userId The ID of the user to send the newsletter to
   * @returns Promise that resolves when the newsletter send is triggered
   */
  async function triggerManualNewsletterSend(userId: string): Promise<void> {
    try {
      // Ensure the user is authenticated
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('You must be logged in to send a newsletter');
      }

      // Get the current user's token to ensure it's fresh
      await currentUser.getIdToken(true);
      console.log(`Attempting to send newsletter to user ${userId} as admin ${currentUser.uid}`);
      
      // Call the triggerManualNewsletterSend Cloud Function
      const triggerManualNewsletterSendFn = httpsCallable(functions, 'triggerManualNewsletterSend');
      const result = await triggerManualNewsletterSendFn({ userId });
      console.log(`Newsletter send trigger result for user ${userId}:`, result.data);
      return result.data as any;
    } catch (error) {
      console.error(`Error triggering newsletter send for user ${userId}:`, error);
      throw error;
    }
  }

  /**
   * Verify a user's email (admin action)
   * @param userId The ID of the user to verify
   * @returns Promise that resolves with the verification result
   */
  async function verifyUserEmail(userId: string): Promise<{ success: boolean }> {
    try {
      // Ensure the user is authenticated
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('You must be logged in to verify user email');
      }

      // Get the current user's token to ensure it's fresh
      await currentUser.getIdToken(true);
      console.log(`Attempting to verify email for user ${userId} as admin ${currentUser.uid}`);
      
      // Call the verifyUserEmail Cloud Function
      const verifyUserEmailFn = httpsCallable(functions, 'verifyUserEmail');
      const result = await verifyUserEmailFn({ userId });
      console.log(`Email verification result for user ${userId}:`, result.data);
      return result.data as { success: boolean };
    } catch (error) {
      console.error(`Error verifying email for user ${userId}:`, error);
      throw error;
    }
  }

  /**
   * Refresh email content and PDF for a specific user
   * @param userId The ID of the user to refresh content for
   * @returns Promise that resolves when the content refresh is complete
   */
  async function refreshUserEmailContent(userId: string): Promise<void> {
    try {
      // Ensure the user is authenticated
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('You must be logged in to refresh user email content');
      }

      // Get the current user's token to ensure it's fresh
      await currentUser.getIdToken(true);
      console.log(`Attempting to refresh email content for user ${userId} as admin ${currentUser.uid}`);
      
      // Call the refreshUserEmailContent Cloud Function
      const refreshUserEmailContentFn = httpsCallable(functions, 'refreshUserEmailContent');
      const result = await refreshUserEmailContentFn({ userId });
      
      // Update the emailBodyUpdatedOnL timestamp in the user document
      const userRef = doc(db, COLL_USERS, userId);
      await updateDoc(userRef, {
        emailBodyUpdatedOnL: Date.now() // Store current time as milliseconds
      });
      
      console.log(`Email content refresh result for user ${userId}:`, result.data);
      return result.data as any;
    } catch (error) {
      console.error(`Error refreshing email content for user ${userId}:`, error);
      throw error;
    }
  }


  /**
   * Get all batch runs from the database
   * @returns Promise that resolves with an array of batch run information
   */
  async function getAllBatchRuns(): Promise<any[]> {
    try {
      const batchRunsRef = collection(db, COLL_BATCH_RUNS);
      const querySnapshot = await getDocs(query(batchRunsRef));
      
      const batchRuns: any[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        batchRuns.push({
          id: doc.id,
          ...data,
          createdOn: data.createdOn?.toDate() || new Date(),
          completedOn: data.completedOn?.toDate() || null
        });
      });
      
      // Sort by creation date (newest first)
      return batchRuns.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
    } catch (error) {
      console.error('Error fetching batch runs:', error);
      return [];
    }
  }

  /**
   * Subscribe to batch runs collection for real-time updates
   * @param callback Function to call with updated batch runs data
   * @returns Unsubscribe function to stop listening for updates
   */
  function subscribeToBatchRuns(callback: (batchRuns: any[]) => void): Unsubscribe {
    const batchRunsRef = collection(db, COLL_BATCH_RUNS);
    return onSnapshot(batchRunsRef, (querySnapshot) => {
      const batchRuns: any[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        batchRuns.push({
          id: doc.id,
          ...data,
          createdOn: data.createdOn?.toDate() || new Date(),
          completedOn: data.completedOn?.toDate() || null
        });
      });
      
      // Sort by creation date (newest first)
      callback(batchRuns.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime()));
    }, (error) => {
      console.error('Error subscribing to batch runs:', error);
      callback([]);
    });
  }

  /**
   * Get list runs for a specific batch
   * @param batchId The ID of the batch to get list runs for
   * @returns Promise that resolves with an array of list run information
   */
  async function getListRunsForBatch(batchId: string): Promise<any[]> {
    try {
      const listRunsRef = collection(db, COLL_LIST_RUNS);
      const q = query(listRunsRef, where('batchId', '==', batchId));
      const querySnapshot = await getDocs(q);
      
      const listRuns: any[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        listRuns.push({
          id: doc.id,
          ...data,
          createdOn: data.createdOn?.toDate() || new Date(),
          completedOn: data.completedOn?.toDate() || null
        });
      });
      
      // Sort by creation date
      return listRuns.sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime());
    } catch (error) {
      console.error(`Error fetching list runs for batch ${batchId}:`, error);
      return [];
    }
  }

  /**
   * Subscribe to list runs for a specific batch for real-time updates
   * @param batchId The ID of the batch to subscribe to list runs for
   * @param callback Function to call with updated list runs data
   * @returns Unsubscribe function to stop listening for updates
   */
  function subscribeToListRunsForBatch(batchId: string, callback: (listRuns: any[]) => void): Unsubscribe {
    const listRunsRef = collection(db, COLL_LIST_RUNS);
    const q = query(listRunsRef, where('batchId', '==', batchId));
    
    return onSnapshot(q, (querySnapshot) => {
      const listRuns: any[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        listRuns.push({
          id: doc.id,
          ...data,
          createdOn: data.createdOn?.toDate() || new Date(),
          completedOn: data.completedOn?.toDate() || null
        });
      });
      
      // Sort by creation date
      callback(listRuns.sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime()));
    }, (error) => {
      console.error(`Error subscribing to list runs for batch ${batchId}:`, error);
      callback([]);
    });
  }

  /**
   * Enqueue a batch run of the specified type
   * @param batchType The numeric type of batch to enqueue (use EnumBatchType enum)
   * @param parentBatchId Optional parent batch ID (required for USER_PREP type)
   * @returns Promise that resolves with the ID of the created batch run
   */
  async function enqueueBatch(batchType: number, parentBatchId?: string): Promise<string | null> {
    try {
      const enqueueBatchFn = httpsCallable(functions, 'enqueueBatchCallable');
      const result = await enqueueBatchFn({ batchType, parentBatchId });
      return result.data as string;
    } catch (error) {
      console.error(`Error enqueueing batch type ${batchType}:`, error);
      return null;
    }
  }
  

  /**
   * Enqueue reserved lists (TOP_LEADERS and TOP_LOSERS) for processing
   * @returns Promise that resolves with the ID of the created batch run
   */
  async function enqueueReservedLists(): Promise<string | null> {
    return enqueueBatch(Number(EnumBatchType.RESERVED_LISTS));
  }
  
  /**
   * Legacy function for backward compatibility
   * @deprecated Use enqueueReservedLists instead
   */
  async function rebuildReservedLists(): Promise<string | null> {
    return enqueueReservedLists();
  }
  
  /**
   * Trigger a user preparation batch with a parent batch ID
   * @param parentBatchId The ID of the parent batch
   * @returns Promise that resolves with the ID of the created batch run
   */
  async function triggerUserPrepBatch(parentBatchId: string): Promise<string | null> {
    return enqueueBatch(Number(EnumBatchType.USER_PREP), parentBatchId);
  }
  
  /**
   * Legacy function for rebuilding reserved lists - kept for backward compatibility
   * @deprecated Use rebuildReservedLists instead
   * @returns Promise that resolves with the result of the rebuild operation
   */
  async function _legacyRebuildReservedLists(): Promise<any> {
    try {
      const rebuildReservedListsFn = httpsCallable(functions, 'rebuildReservedListsCallable');
      const result = await rebuildReservedListsFn();
      return result.data;
    } catch (error) {
      console.error('Error rebuilding reserved lists:', error);
      throw error;
    }
  }

  /**
   * Subscribe to system run settings document in Firestore
   * @param callback Function to call when settings change
   * @returns Unsubscribe function
   */
  function subscribeToSystemRunSettings(callback: (settings: any) => void): Unsubscribe {
    try {
      // Check if the user is authenticated
      if (!auth.currentUser) {
        throw new Error('User must be authenticated to subscribe to system settings');
      }
      
      const settingsRef = doc(db, 'settings', 'systemRun');
      
      // Subscribe to the document
      return onSnapshot(settingsRef, (snapshot) => {
        if (!snapshot.exists()) {
          // Return default settings if document doesn't exist
          callback({
            autoRun: false,
            autoRunPeriod: 5,
            isBatchListRunInProgress: false,
            runStdListRefresh: false,
            runUserRefresh: false
          });
          return;
        }
        
        const data = snapshot.data();
        
        // Convert timestamp to Date if it exists
        if (data.timestamp && typeof data.timestamp.toDate === 'function') {
          data.timestamp = data.timestamp.toDate();
        }
        if (data.lastBatchRunStart && typeof data.lastBatchRunStart.toDate === 'function') {
          data.lastBatchRunStart = data.lastBatchRunStart.toDate();
        }
        
        callback(data);
      }, (error) => {
        console.error('Error subscribing to system run settings:', error);
      });
    } catch (error) {
      console.error('Error setting up subscription to system run settings:', error);
      throw error;
    }
  }

  /**
   * Get system run settings from Firestore
   * @returns Promise that resolves with the system run settings
   */
  async function getSystemRunSettings(): Promise<any> {
    try {
      // Check if the user is authenticated
      if (!auth.currentUser) {
        throw new Error('User must be authenticated to get system settings');
      }

      // Check if the user is an admin by querying their user document
      const userRef = doc(db, COLL_USERS, auth.currentUser.uid);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data() as UserInfo2;
      
      if (!userData?.isAdmin) {
        throw new Error('Admin privileges required to get system settings');
      }
      
      const settingsRef = doc(db, 'settings', 'systemRun');
      const settingsSnap = await getDoc(settingsRef);
      
      if (!settingsSnap.exists()) {
        // Return default settings if document doesn't exist
        return {
          autoRun: false,
          autoRunPeriod: 5,
          isBatchListRunInProgress: false,
          runStdListRefresh: false,
          runUserRefresh: false
        };
      }
      
      const data = settingsSnap.data();
      
      // Convert timestamp to Date if it exists
      if (data.lastBatchRunStart && typeof data.lastBatchRunStart.toDate === 'function') {
        data.lastBatchRunStart = data.lastBatchRunStart.toDate();
      }
      
      return data;
    } catch (error) {
      console.error('Error getting system run settings:', error);
      
      // Return default settings on error
      return {
        autoRun: false,
        autoRunPeriod: 5,
        isBatchListRunInProgress: false,
        runStdListRefresh: false,
        runUserRefresh: false
      };
    }
  }

  /**
   * Update system settings in Firestore
   * @param settings Object containing system settings to update
   * @returns Promise that resolves when the update is complete
   */
  async function updateSystemSettings(settings: Record<string, any>): Promise<void> {
    try {
      // Check if the user is authenticated
      if (!auth.currentUser) {
        throw new Error('User must be authenticated to update system settings');
      }
      
      // Check if the user is an admin by querying their user document
      const userRef = doc(db, COLL_USERS, auth.currentUser.uid);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data() as UserInfo2;
      
      if (!userData?.isAdmin) {
        throw new Error('Admin privileges required to update system settings');
      }
      
      const settingsRef = doc(db, 'settings', 'systemRun');
      
      // Add timestamp to track when settings were last updated
      const updatedSettings = {
        ...settings,
        timestamp: serverTimestamp()
      };
      
      await setDoc(settingsRef, updatedSettings, { merge: true });
      console.log('System settings updated successfully:', settings);
    } catch (error) {
      console.error('Error updating system settings:', error);
      throw error;
    }
  }

  /**
   * Process the next batch of list runs
   * @returns Promise that resolves with the result of the batch processing
   */
  async function processNextBatch(): Promise<any> {
    try {
      const processNextBatchFn = httpsCallable(functions, 'processNextBatchCallable');
      const result = await processNextBatchFn({});
      return result.data;
    } catch (error) {
      console.error('Error processing next batch:', error);
      throw error;
    }
  }



  /**
   * Delete a batch run and all its associated list runs
   * @param batchId The ID of the batch run to delete
   * @returns Promise that resolves when the deletion is complete
   */
  async function deleteBatchRun(batchId: string): Promise<boolean> {
    try {
      // First, get all list runs for this batch
      const listRunsRef = collection(db, COLL_LIST_RUNS);
      const q = query(listRunsRef, where('batchId', '==', batchId));
      const querySnapshot = await getDocs(q);
      
      // Delete each list run in a batch operation
      const batch = writeBatch(db);
      querySnapshot.forEach((docSnapshot) => {
        batch.delete(docSnapshot.ref);
      });
      
      // Delete the batch run itself
      const batchRunRef = doc(db, COLL_BATCH_RUNS, batchId);
      batch.delete(batchRunRef);
      
      // Commit the batch operation
      await batch.commit();
      
      console.log(`Successfully deleted batch run ${batchId} and ${querySnapshot.size} list runs`);
      return true;
    } catch (error) {
      console.error(`Error deleting batch run ${batchId}:`, error);
      return false;
    }
  }

  /**
   * Reset a batch to its default runnable state
   * @param batchId ID of the batch to reset
   * @returns Promise<boolean> whether the reset was successful
   */
  async function resetBatch(batchId: string): Promise<boolean> {
    try {
      const resetBatchFn = httpsCallable(functions, 'resetBatchCallable');
      const result = await resetBatchFn({ batchId });
      return (result.data as any).success;
    } catch (error) {
      console.error(`Error resetting batch ${batchId}:`, error);
      return false;
    }
  }

  /**
   * Process a document using Google Document AI
   * @param fileData Base64 encoded file data
   * @param fileName Name of the file
   * @param mimeType MIME type of the file
   * @returns Promise with the processing result
   */
  async function processDocument(fileData: string, fileName: string, mimeType: string): Promise<any> {
    try {
      const processDocumentFn = httpsCallable(functions, 'processDocumentCallable');
      const result = await processDocumentFn({ fileData, fileName, mimeType });
      return result.data;
    } catch (error) {
      console.error('Error processing document:', error);
      throw error;
    }
  }

  /**
   * Extract stock tickers from an image using the AI image handler
   * @param imageBase64 Base64 encoded image data
   * @returns Promise with the extracted tickers
   */
  async function getTickersFromImage(imageBase64: string): Promise<string[]> {
    try {
      const extractTickersFn = httpsCallable(functions, 'getTickersFromImageCallable');
      const result = await extractTickersFn({ imageBase64 });
      return (result.data as any).tickers || [];
    } catch (error) {
      console.error('Error extracting tickers from image:', error);
      throw error;
    }
  }


  return {
    isInitialized,
    error,
    enrichUserInfo,
    confirmCustomer,
    processDocument,
    getTickersFromImage,
    isSubscribed,
    addNews,
    addUserList,
    addItemToWatchlist,
    cleanup,
    deleteList,
    getListsForUser,
    getVisitors,
    getItemDoc,
    getNewsDoc,
    getNewsItemSnapshot,
    getItemsSnapshot,
    getListInfo,
    getUserByEmail,
    getCustomerByEmail,
    getVisitorCountSnapshot,
    getListNewsInfo,
    getMultipleListsNewsInfo,
    getListData,
    getHttpsCallable,
    subscribeToNewsletter,
    saveUserRemote,
    updateVisitorCount,
    createCheckoutSession,
    getSubscriptionsSnapshot,
    getPaymentInfosSnapshot,
    querySnapshotToDataWithId,
    getListInfosForUser,
    generateFirestoreGraphText,
    getAllUsers,
    subscribeToUsers,
    deleteUser,
    triggerManualNewsletterSend,
    refreshUserEmailContent,
    verifyUserEmail,
    getAllBatchRuns,
    subscribeToBatchRuns,
    getListRunsForBatch,
    subscribeToListRunsForBatch,

    deleteBatchRun,
    resetBatch,
    processNextBatch,

    rebuildReservedLists,
    enqueueReservedLists,
    enqueueBatch,
    triggerUserPrepBatch,
    getSystemRunSettings,
    updateSystemSettings,
    subscribeToSystemRunSettings,
     // hasActiveSubscription
  }
}
