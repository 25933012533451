export default class DateTimeHelper {
    public static getCurrentDateTime(): string {
        return new Date().toISOString();
    }

    static getNow():Date {
        return new Date();
    }

  static getFormattedNow() {
    return new Date().toISOString();
  }
}
