/**
 * Copied this after some implementation looking at the docqanda/pdfqanda project
 * Should use this everywhere
 */
export enum EnumCollections {
    COL_EMAIL = "mail",
    COL_USERS = "users",
    COL_PAYMENTS = "payments",
    COL_SUBSCRIPTIONS = "subscriptions",
    COL_SYSTEM_SETTINGS = "settings",
}
  